.workspace-selector-wrapper {
    list-style: none;
}

.workspace-toggle {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 8px 12px;
    border-radius: 5px;
    color: #f5f5f5;
    font-weight: 500;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.workspace-toggle:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}



.workspace-dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    padding: 0.5rem 0;
}

.workspace-item {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    transition: transform 0.2s ease-in-out;
}

.workspace-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.workspace-info .country-flag {
    display: inline-block;
    flex-shrink: 0;
}

.workspace-link {
    color: #333333;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.2s ease-in-out;
}

.workspace-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.chevron-icon {
    margin-left: 8px;
    font-size: 0.8rem;
}

.workspace-icon {
    margin-right: 8px;
    color: #0066cc;
    font-size: 1.1rem;
}

.workspace-search-container {
    padding: 0.5rem 1rem;
    width: 100%;
}
.workspace-search {
    margin: 0.5rem 1rem 0.5rem 1rem;
    padding: 0.4rem;
    width: calc(100% - 2rem);
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.workspace-search:focus {
    border-color: #0056b3;
    box-shadow: 0 0 4px rgba(0, 86, 179, 0.3);
}
.workspace-dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    padding: 0;
    max-height: 50vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.workspace-search-container {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #ffffff;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #ccc;
}